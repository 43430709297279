@import "globals";

@import (less) "../node_modules/normalize.css/normalize.css";
@import "lib/base";
@import "lib/functions";
@import "colors";

@import (once) "fonts/fonts";

@import "layout/layout";
@import "layout/header";
@import "layout/columns";
@import "layout/icons";

@import "pages/login";
@import "pages/calendarPages";

@import "widgets/avatar";
@import "widgets/teamMember";
@import "widgets/dots";

@import "widgets/form";
@import "widgets/formPill";
@import "widgets/formLayout";

@import "widgets/rc/checkbox";
//@import "widgets/rc/slider";
@import "widgets/rc/pagination";
@import "widgets/rc/select";
@import "widgets/rc/tooltip";
@import "widgets/colorPicker";

@import "widgets/rc/calendar";
@import (less)'../node_modules/rc-time-picker/assets/index.css';

@import "widgets/modal";
@import "widgets/table";
@import "widgets/pill";
@import "widgets/popupMenu";
@import "widgets/filter";
@import "widgets/confirmAlert";
@import "widgets/button";
@import "widgets/calendar/calendar";
@import "widgets/spinner";

@import "elements";

// code
#root {
	height: 100%;
}

#container {
	height: 100%;

	&.centerVertically {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;
	}
}

.blue { color: @blue; }
.red { color: @red; }
.light { color: #9FA2B1; }

.sortableHelper {
	z-index: 10000;
}

@media (max-width: 690px) {
	.hideOnMobile {
		display: none !important;
	}
}

.dragAndDropBackground {
	background-color: #EEE;
}

@import "lib/dimensions";
@import "lib/typo";
@import "lib/helper";
