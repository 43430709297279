@import (reference) "../globals";
@import (reference) "../colors";
@import "../lib/typo";

.errorMessage {
	color: @red;
	margin-top: 4px;
	margin-bottom: 12px;
}

.formItem {
	margin-bottom: 24px;

	label {
		margin-bottom: 10px;
		display: block;
	}

	.info {
		font-weight: 100;
		font-style: italic;
		font-size: 12px;
	}
}

.boxItem {
	padding: 10px calc(@borderRadius * 1.6);
	background-color: @white;
	border: 1px solid @grey;
	min-height: 100px;
	position: relative;
	.textPreventBreakouts();

	&.small {
		min-height: 0;
	}

	.popupMenu {
		position: absolute;
		top: 4px;
		right: 0;

		ul.popupContents {
			right: -12px;
		}
	}
}