@import "../../colors";

@paginationPrefixClass: rc-pagination;
@paginationColor: @blue;
@paginationRadius: 25px;

.@{paginationPrefixClass} {
	user-select: none;
	padding: 0;
	display: inline-block;

	> li {
		list-style: none;
		outline: 0;
	}

	&-total-text {
		float: left;
		height: 32px;
		line-height: 32px;
		list-style: none;
		padding: 0;
		margin: 0 8px 0 0;
	}

	&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
		overflow: hidden;
		visibility: hidden;
	}

	&-item {
		cursor: pointer;
		min-width: 32px;
		height: 32px;
		line-height: 30px;
		text-align: center;
		list-style: none;
		float: left;
		border: 1px solid #d9d9d9;
		background-color: #fff;
		margin-right: 8px;
		border-radius: @paginationRadius;

		a {
			text-decoration: none;
			color: #666;
		}

		&:hover {
			border-color: @paginationColor;
			a {
				color: @paginationColor;
				text-decoration: none;
			}
		}

		&-disabled {
			cursor: not-allowed;
			&:hover {
				border-color: #d9d9d9;
				a {
					color: #d9d9d9;
				}
			}
		}

		&-active {
			background-color: @paginationColor;
			border-color: @paginationColor;

			a {
				color: #fff;
			}

			&:hover {
				a {
					color: #fff;
				}
			}
		}
	}

	&-jump-prev, &-jump-next {
		&:after {
			content: "•••";
			display: block;
			letter-spacing: 0;
			color: #ccc;
			margin-top: 1px;
		}

		&:hover {
			&:after {
				color: @paginationColor;
			}
		}

	}

	&-jump-prev {
		&:hover {
			&:after {
				content: "«";
				.font24();
				line-height: 1;
			}
		}
	}

	&-jump-next {
		&:hover {
			&:after {
				content: "»";
				.font24();
				line-height: 1;
			}
		}
	}

	&-jump-prev-custom-icon,
	&-jump-next-custom-icon {
		position: relative;

		&:after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transition: all .2s;

			content: "•••";
			opacity: 1;
			display: block;
			letter-spacing: 2px;
			color: #ccc;
			.font12();
			margin: 1px auto auto;
		}

		.custom-icon-jump-prev,
		.custom-icon-jump-next {
			opacity: 0;
			transition: all .2s;
		}

		&:hover {

			&:after {
				opacity: 0;
				color: #ccc;
			}

			.custom-icon-jump-prev,
			.custom-icon-jump-next {
				opacity: 1;
				color: @paginationColor;
			}
		}
	}

	&-prev, &-jump-prev, &-jump-next {
		margin-right: 8px;
	}
	&-prev, &-next, &-jump-prev, &-jump-next {
		cursor: pointer;
		color: #666;
		list-style: none;
		min-width: 32px;
		height: 32px;
		line-height: 30px;
		float: left;
		text-align: center;
		border-radius: @paginationRadius;
	}

	&-prev {
		a {
			&:after {
				content: "<";
				display: block;
			}
		}
	}

	&-next {
		a {
			&:after {
				content: ">";
				display: block;
			}
		}
	}

	&-prev, &-next {
		background-color: #fff;
		border: 1px solid #d9d9d9;

		a {
			color: #666;

			&:after {
				margin-top: -1px;
			}

			&:hover {
				text-decoration: none;
			}
		}
	}

	&-disabled {
		cursor: not-allowed;
		a {
			color: #ccc;
		}
	}

	&-options {
		float: left;
		margin-left: 15px;
		&-size-changer {
			float: left;
			width: 80px;
		}

		&-quick-jumper {
			float: left;
			margin-left: 16px;
			height: 28px;
			line-height: 28px;

			input {
				margin: 0 8px;
				box-sizing: border-box;
				background-color: #fff;
				border-radius: 6px;
				border: 1px solid #d9d9d9;
				outline: none;
				padding: 3px 12px;
				width: 50px;
				height: 28px;

				&:hover {
					border-color: @paginationColor;
				}
			}

			button {
				display: inline-block;
				margin: 0 8px;
				font-weight: 500;
				text-align: center;
				touch-action: manipulation;
				cursor: pointer;
				background-image: none;
				border: 1px solid #d9d9d9;
				white-space: nowrap;
				padding: 0 15px;
				font-size: 12px;
				border-radius: 6px;
				height: 28px;
				user-select: none;
				transition: all .3s cubic-bezier(.645,.045,.355,1);
				position: relative;
				color: rgba(0,0,0,.65);
				background-color: #fff;

				&:hover, &:active, &:focus {
					color: @paginationColor;
					background-color: #fff;
					border-color: @paginationColor;
				}
			}
		}
	}

	&-simple {
		.@{paginationPrefixClass}-prev, .@{paginationPrefixClass}-next {
			border: none;
			height: 24px;
			line-height: 24px;
			margin: 0;
			font-size: 18px;
		}

		.@{paginationPrefixClass}-simple-pager {
			float: left;
			margin-right: 8px;
			list-style: none;

			.@{paginationPrefixClass}-slash {
				margin: 0 10px;
			}

			input {
				margin: 0 8px;
				box-sizing: border-box;
				background-color: #fff;
				border-radius: 6px;
				border: 1px solid #d9d9d9;
				outline: none;
				padding: 5px 8px;
				min-height: 20px;

				&:hover {
					border-color: @paginationColor;
				}
			}

			button {
				display: inline-block;
				margin: 0 8px;
				font-weight: 500;
				text-align: center;
				touch-action: manipulation;
				cursor: pointer;
				background-image: none;
				border: 1px solid #d9d9d9;
				white-space: nowrap;
				padding: 0 8px;
				font-size: 12px;
				border-radius: 6px;
				height: 26px;
				user-select: none;
				transition: all .3s cubic-bezier(.645,.045,.355,1);
				position: relative;
				color: rgba(0,0,0,.65);
				background-color: #fff;

				&:hover, &:active, &:focus {
					color: @paginationColor;
					background-color: #fff;
					border-color: @paginationColor;
				}
			}

		}
	}
}

@media only screen and (max-width: 1024px) {
	.@{paginationPrefixClass}-item {
		&-after-jump-prev,
		&-before-jump-next {
			display: none;
		}
	}
}