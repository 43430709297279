html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body { line-height: 1.5; }

pre, cite, code, kbd, samp { font-family: 'Andale Mono', 'Courier New', Courier, monospace; }

hr {
	border: 0;
	border-top: 1px solid #888;
	margin: 1.5em 0;
	padding: 0;
}

nav ul { list-style: none; padding-left: 0; }
ol { list-style: decimal; }
ul { list-style: disc; }
ul ul { list-style: circle; }
ul, ol, dd { padding-left: 2.5em; }

/* Remove possible quote marks (") from <q>, <blockquote>. */
blockquote { padding-left: 1.25em; }
blockquote, q { 
	quotes: none;
	font-style: italic;
}
blockquote:before, blockquote:after, q:before, q:after { 
	content: '';
	content: none;
}

p, pre { margin-bottom: 1.5em; }

a:focus { outline: none; }

ul, ol, dl, hr, blockquote, code {
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

ul ul, ul ol, ul dl, ol ol, ol ul, ol dl, dl dl, dl ul, dl ol { 
	margin-top: 0;
	margin-bottom: 0;
}

h1, h2 { font-size: 2em; }
h3 { font-size: 1.31em; }
h4 { font-size: 1.13em; }
h5 { font-size: 1em; }
h6 { font-size: 1em; font-weight: normal; }
h1, h2, h3, h4, h5, h6 { margin: 0.5em 0; }

ins {
	text-decoration: none;
	background-color: #ff9;
	color: #000;
}
mark {
	background-color: #ff9;
	color: #000; 
	font-style: italic;
	font-weight: bold;
}
del { text-decoration: line-through; }

abbr[title], dfn[title] {
	border-bottom: 1px dotted #000;
	cursor:help;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
	border-collapse: collapse;

	td, th {
		&.textLeft { text-align: left; }
		&.textRight { text-align: right; }
		&.textCenter { text-align: center; }
		&.textJustify { text-align: justify; }
	}
}

table.pretty {
	td, th {
		border: 1px solid #ccc;

		&.textLeft { text-align: left; }
		&.textRight { text-align: right; }
		&.textCenter { text-align: center; }
		&.textJustify { text-align: justify; }
	}
	td, th, caption { padding: 0.25em 0.5em; }
	th { background-color: #c2d3d2; text-align: left; font-weight: 400; }
	tr:nth-child(even) { background-color: #f4f4f4; }
	tfoot { font-style: italic; }
}

textarea { font-family: inherit; }
input, button, select { vertical-align: baseline; font-family: inherit; }
input, textarea { outline: 0; }

button { cursor: pointer; }

input[type="number"],
input[type="text"],
input[type="email"],
input[type="search"],
input[type="tel"],
input[type="password"],
input[type="submit"],
input[type="reset"],
button, select, textarea {
	border-radius: 0;
	padding: 0.25em;
	background-color: #F0F0F0;
	border: 1px solid #888;
}

select {
	appearance: none;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;

	&::-ms-expand {	display: none; }

	&[multiple] {
		background-image: none;
	}
}


legend { font-weight: bold; padding: 0 0.5em; }
fieldset {
	padding: 0.5em 1em;
	margin-bottom: 1.5em;
	border: 1px solid #888;
}

iframe { border: 0; }