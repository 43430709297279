@import "../../colors";

@checkboxWarpPrefixCls: rc-checkbox;
@checkboxInnerPrefixCls: ~"@{checkboxWarpPrefixCls}-inner";
@checkBoxColor: @blue;
@checkBoxBorderColor: @grey;

/* Default state */
.@{checkboxWarpPrefixCls} {
	white-space: nowrap;
	cursor: pointer;
	outline: none;
	display: inline-block;
	position: relative;
	line-height: 1;
	vertical-align: middle;

	&:hover .@{checkboxInnerPrefixCls},
	&-input:focus + .@{checkboxInnerPrefixCls} {
		border-color: @checkBoxColor;
	}

	&-inner {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		width: 18px;
		height: 18px;
		border-width: 1px;
		border-style: solid;
		border-radius: 3px;
		border-color: @checkBoxBorderColor;
		background-color: @white;
	}

	&-input {
		position: absolute;
		left: 0;
		z-index: 2000;
		cursor: pointer;
		opacity: 0;
		top: 0;
		bottom: 0;
		right: 0;
	}
}

/* Checked state */
.@{checkboxWarpPrefixCls}-checked {

	&:hover {
		.@{checkboxInnerPrefixCls} {
			border-color: @checkBoxColor;
		}
	}

	.@{checkboxInnerPrefixCls} {
		&:after {
			background-color: @checkBoxColor;
			position: absolute;
			left: 3px;
			top: 3px;
			width: 10px;
			height: 10px;
			border-radius: 3px;
			content: ' ';
			.transition();
		}
	}
}

.@{checkboxWarpPrefixCls}-disabled {

	input {
		cursor: default;
	}

	&.@{checkboxWarpPrefixCls}-checked {

		&:hover {
			.@{checkboxInnerPrefixCls} {
				border-color: @checkBoxBorderColor;
			}
		}

		.@{checkboxInnerPrefixCls} {
			background-color: #f3f3f3;
			border-color: @checkBoxBorderColor;

			&:after {
				animation-name: none;
				border-color: #cccccc;
			}
		}
	}

	&:hover {
		.@{checkboxInnerPrefixCls} {
			border-color: @checkBoxBorderColor;
		}
	}

	.@{checkboxInnerPrefixCls} {
		border-color: @checkBoxBorderColor;
		background-color: #f3f3f3;
		opacity: 0.6;
		cursor: default;

		&:after {
			animation-name: none;
			border-color: #f3f3f3;
		}
	}

	.@{checkboxInnerPrefixCls}-input {
		cursor: default;
	}
}
