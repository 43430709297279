.boxShadow(@offseth: 0, @offsetv: 6px, @radius: 10px, @color: rgba(0, 0, 0, 0.05)) {
	box-shadow: @offseth @offsetv @radius @color;
	background-clip: padding-box;
}
.boxShadowInner(@offseth: 1px, @offsetv: 1px, @radius: 6px, @color: rgba(0, 0, 0, 0.05)) {
	box-shadow: inset @offseth @offsetv @radius @color;
}
.boxShadowOff() { box-shadow: none; }

.backgroundGradient(@startColor: #ccc, @endColor: white) {
	background-color: mix(@startColor, @endColor);
	background-image: linear-gradient(to bottom, @startColor 0%, @endColor 100%);
}

.transition(@property:all, @duration:0.4s, @ease:ease-out) { transition: @property @duration @ease; }