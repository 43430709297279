.filters {
	.filter {
		display: inline-block;
		margin-right: 8px;
		padding: 6px 12px;
		color: #273142;
		background-color: darken(#F2F4F9, 3);
		border-radius: 100px;
		margin-bottom: 16px;

		i {
			//color: #A6A6A6;
			color: @red;
			margin-right: 4px;
			cursor: pointer;
		}

		&:hover {
			i { color: @red; }
		}
	}
}