@import (reference) "../globals";
@import (reference) "../colors";
@import (reference) "../lib/typo";
@import (reference) "../lib/helper";
@import (reference) "../lib/functions";

.boxStyle() {
	background: @white;
	.boxShadow();
	border-radius: @borderRadius;
	padding: 32px;
	margin-bottom: 36px;

	&.smallerPadding {
		padding-top: 24px;
		padding-bottom: 24px;
	}
}

#container {
	aside {
		display: block;
		height: 100%;
		float: left;
		background-color: @white;
		position: fixed;
		.boxShadow();
		overflow: auto;

		nav {
			width: @sidebarWidth;
			padding: 32px;
			.font16();

			ul {
				margin: 0;

				li {
					color: #747474;
					margin-bottom: 18px;

					span { cursor: default; }

					a {
						color: #747474;
						font-weight: normal;

						.avatar { vertical-align: sub; }

						&.selected { font-weight: bold; }
					}

					ul {
						margin-top: 21px;
						list-style: none;
						padding-left: 12px;
					}
				}

				hr {
					margin-bottom: 21px;
				}
			}
		}
	}

	> main {
		margin-left: @sidebarWidth;
		padding: 64px 32px 40px;
		max-width: 1560px;
	}
}

.box {
	.boxStyle();

	.boxEntry {
		border-bottom: 1px solid #D8D8D8;
		margin-bottom: 16px;
		padding-bottom: 16px;

		&:last-of-type {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom-width: 0;
		}

	}
}
