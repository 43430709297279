@import (reference) "../globals";
@import (reference) "../colors";
@import (reference) "../lib/helper";

#container {
	header.top {
		background-color: #545A6E;
		color: #E9EFF6;
		padding: 6px 55px;
		position: fixed;
		left: @sidebarWidth;
		right: 0;
		top: 0;
		overflow: hidden;
		z-index: 1;
		min-height: 44px;

		.avatar {
			border: 0;
			.boxShadow(0, 2px, 5px, rgba(0, 0, 0, 0.6));
		}

		.right {
			float: right;
			font-size: 14px;
		}

		a, .link {
			color: #E9EFF6;
			display: inline-block;
			margin-right: 12px;
		}

		.logout {
			margin-left: 12px;
			margin-right: 0;
		}

		.notificationCount {
			vertical-align: super;
			display: inline-block;
			text-align: center;
			background-color: @red;
			border-radius: 100px;
			.font12;
			min-width: 20px;
			height: 20px;
			padding: 1px 4px;
		}
	}
}


.headerWithButtons {
	margin: 12px 0 32px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;


	h1, h2, h3, h4, h5, h6 {
		margin: 0;
		display: inline-block;
		float: left;
		line-height: 1.1;
	}

	.left {
		float: left;
		overflow: hidden;

		button, a.button, .rc-select { float: left; }
	}

	.right {
		float: right;
		margin-left: 12px;
	}
}