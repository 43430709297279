.loop(@amount) {
	.top@{amount} { margin-top: ~"@{amount}px !important"; }
	.padTop@{amount} { padding-top: ~"@{amount}px !important"; }

	.bottom@{amount} { margin-bottom: ~"@{amount}px !important"; }
	.padBottom@{amount} { padding-bottom: ~"@{amount}px !important"; }

	.left@{amount} { margin-left: ~"@{amount}px !important"; }
	.padLeft@{amount} { padding-left: ~"@{amount}px !important"; }

	.right@{amount} { margin-right: ~"@{amount}px !important"; }
	.padRight@{amount} { padding-right: ~"@{amount}px !important"; }

	.lift@{amount} { margin-top: ~"-@{amount}px !important"; }
}
.loop(0);
.loop(1);
.loop(2);
.loop(4);
.loop(6);
.loop(8);
.loop(10);
.loop(12);
.loop(16);
.loop(20);
.loop(24);
.loop(32);
.loop(40);
.loop(48);
.loop(64);
.loop(80);
.loop(96);
.loop(128);