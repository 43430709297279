.boxBorder {
	border: 1px solid #D7DDE7;
	background: @white;
	border-radius: @borderRadius;

	header {
		padding: 16px 20px;
		border-bottom: 1px solid #D7DDE7;
		overflow: hidden;

		h3 { margin: 5px 0 0; }
	}

	main {
		padding: 20px;
	}
}

.calendarDailyPage {
	.clearFix();

	.calendarWrapper {
		float: left;
	}

	.boxBorder {
		margin-left: 340px;
	}
}