@import (reference) "../globals";

.textLeft { text-align: left; }
.textRight { text-align: right; }
.textCenter { text-align: center; }
.textJustify { text-align: justify; }

.textBold, .bold { font-weight: bold; }
.textNormal, .normal { font-weight: normal; }
.textLight, .light { font-weight: lighter; }

.textItalic { font-style: italic; }

.textHide() { text-indent: 101%; overflow: hidden; white-space: nowrap; }
.hideText { .textHide(); }
.textHide { .textHide(); }

.textCaps, .textCapitalize { text-transform: uppercase; }

.textPreventBreakouts {
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	hyphens: auto;
}

.valignMiddle { vertical-align: middle; }
.valignTop { vertical-align: top; }
.valignBottom { vertical-align: bottom; }

.font8 { font-size: (8/@baseFontSize) + 0rem; }
.font9 { font-size: (9/@baseFontSize) + 0rem; }
.font10 { font-size: (10/@baseFontSize) + 0rem; }
.font11 { font-size: (11/@baseFontSize) + 0rem; }
.font12 { font-size: (12/@baseFontSize) + 0rem; }
.font13 { font-size: (13/@baseFontSize) + 0rem; }
.font14 { font-size: (14/@baseFontSize) + 0rem; }
.font15 { font-size: (15/@baseFontSize) + 0rem; }
.font16 { font-size: (16/@baseFontSize) + 0rem; }
.font18 { font-size: (18/@baseFontSize) + 0rem; }
.font21 { font-size: (21/@baseFontSize) + 0rem; }
.font24 { font-size: (24/@baseFontSize) + 0rem; }
.font28 { font-size: (28/@baseFontSize) + 0rem; }
.font32 { font-size: (32/@baseFontSize) + 0rem; }
.font40 { font-size: (40/@baseFontSize) + 0rem; }
.font48 { font-size: (48/@baseFontSize) + 0rem; }
.font64 { font-size: (64/@baseFontSize) + 0rem; }

.fontLight { font-weight: 100; }
.fontNormal { font-weight: 400; }
.fontBold { font-weight: 700; }

.lineHeight1 { line-height: 1; }
.lineHeight11 { line-height: 1.1; }
.lineHeight12 { line-height: 1.2; }
.lineHeight13 { line-height: 1.3; }
.lineHeight14 { line-height: 1.4; }
.lineHeight15 { line-height: 1.5; }
.lineHeight16 { line-height: 1.6; }

.letterSpacingNormal { letter-spacing: normal; }
.letterSpacing1 { letter-spacing: 1px; }
.letterSpacing2 { letter-spacing: 2px; }
.letterSpacing3 { letter-spacing: 3px; }

.preventTextSelection { user-select: none }