@import (reference) "../globals";
@import (reference) "../colors";

.loginWrapper {
	text-align: center;

	h2 {
		margin-bottom: 36px;
		font-size: 22px;
		line-height: 29px;
	}

	input { text-align: center; }
}

.loginBox {
	padding: 48px 40px 40px;
	background: @white;
	.boxShadow();
	border-radius: @borderRadius;
	display: inline-block;
	margin-bottom: 21px;
	width: 400px;

	input[type="text"],
	input[type="email"],
	input[type="search"],
	input[type="tel"],
	input[type="password"],
	input[type="number"],
	select, textarea {
		border-radius: 100px;
	}
}