@import (reference) "../colors";
@import (reference) "../lib/functions";

.colorDot, .clientDot, .contactDot {
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	.transition();
	border-radius: 32px;
	width: 32px;
	height: 32px;
	line-height: 32px;
	color: @white;
}

.colorDot {
	background-color: @grey;
	margin-right: 8px;

	&.small {
		width: 16px;
		height: 16px;
		line-height: 16px;
		margin-right: 4px;
	}
}

.clientDot {
	background-color: @blue;
	margin-right: 4px;

	&:last-of-type { margin-right: 0; }

	&.green {
		background-color: darken(#91FE66, 20%);
	}
	&.orange {
		background-color: #F19A5C;
	}
	&.cyan {
		background-color: #6BE0E5;
	}
}

.contactDot {
	background-color: @blue;

	&:hover { background-color: darken(@blue, 20%); }

	i { line-height: 32px; }
}

.rc-select-selection-item {
	.colorDot, .clientDot {
		margin-top: -6px;

		&.small { margin-top: -3px; }
	}
}