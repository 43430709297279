@import "../../colors";
@import "../../globals";

@select-prefix: ~'rc-select';
@selectArrowSize: 12px;

.search-input-without-border() {
	.@{select-prefix}-selection-search-input {
		border: none;
		outline: none;
		width: 100%;
	}
}

.@{select-prefix} {
	display: inline-block;
	position: relative;
	outline: 0;

	&-disabled {
		.@{select-prefix}-selector, .@{select-prefix}-arrow {
			opacity: 0.6;
		}
	}

	&-show-arrow&-loading {
		.@{select-prefix}-arrow {
			&-icon::after {
				box-sizing: border-box;
				width: 12px;
				height: 12px;
				border-radius: 100%;
				border: 2px solid @blue;
				border-top-color: transparent;
				border-bottom-color: transparent;
				transform: none;
				margin-top: 6px;
				animation: rcSelectLoadingIcon 0.9s infinite;
			}
		}

		.@{select-prefix}-selector {
			padding-right: 32px;
		}
	}

	// ============== Selector ===============
	.@{select-prefix}-selection-placeholder {
		opacity: 0.4;
		line-height: 21px;
	}

	.@{select-prefix}-selector {
		padding: 7px @borderRadius 7px @borderRadius;
		background-color: @white;
		border: 1px solid @grey;
		border-radius: @borderRadius;
		min-width: @inputMinWidth;
	}

	&.noMinimumWidth {
		.@{select-prefix}-selector {
			min-width: 0;

			.@{select-prefix}-selection-search {
				&-input {
					min-width: 0;
				}
			}
		}
	}

	// --------------- Single ----------------
	&-single {
		.@{select-prefix}-selector {
			display: flex;
			position: relative;

			.@{select-prefix}-selection-search {
				width: 100%;

				&-input {
					width: 100%;
					height: 24px;
					padding: 0;
				}
			}

			.@{select-prefix}-selection-item,
			.@{select-prefix}-selection-placeholder {
				position: absolute;
				pointer-events: none;
				left: @borderRadius;
				top: 9px;
				right: 25px;
				height: 21px;
				text-align: left;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		.@{select-prefix}-clear {
			&-icon {
				line-height: 0;
				text-align: center;
				text-transform: none;
				position: absolute;
				top: 11px;
				right: 6px;
				font-weight: bold;
				cursor: pointer;
				.transition();
				display: inline-block;
				.font14();

				&:hover { color: @red; }
			}
		}

		.search-input-without-border();
	}

	// -------------- Multiple ---------------
	&-multiple .@{select-prefix}-selector {
		display: flex;
		flex-wrap: wrap;
		padding-top: 3px;

		.@{select-prefix}-selection-item {
			flex: none;
			background: @greyLight;
			border-radius: 4px;
			margin-right: 4px;
			padding: 3px 20px 3px 8px;
			margin-top: 4px;
			position: relative;

			&-disabled {
				cursor: not-allowed;
				opacity: 0.5;
			}

			&-remove {
				line-height: 0;
				text-align: center;
				text-transform: none;
				position: absolute;
				top: 11px;
				right: 6px;
				font-weight: bold;
				cursor: pointer;
				.transition();
				display: inline-block;
				.font14();

				&:hover { color: @red; }
			}
		}

		.@{select-prefix}-selection-placeholder {
			line-height: 24px;
		}

		.@{select-prefix}-selection-search {
			position: relative;

			&-input, &-mirror {
				padding: 4px;
				margin-top: 4px;
			}

			&-mirror {
				position: absolute;
				z-index: 999;
				white-space: nowrap;
				position: none;
				left: 0;
				top: 0;
				visibility: hidden;
			}
		}

		.search-input-without-border();
	}

	// ================ Icons ================
	&-allow-clear {
		&.@{select-prefix}-single .@{select-prefix}-selector {
			padding-right: 36px !important;;
		}

		&.@{select-prefix}-multiple .@{select-prefix}-selector {
			padding-right: 20px;
		}

		.@{select-prefix}-clear {
			position: absolute;
			right: 24px;
			top: 8px;
		}
	}

	&-show-arrow {
		&.@{select-prefix} .@{select-prefix}-selector {
			padding-right: @selectArrowSize + (@borderRadius * 2);
		}

		.@{select-prefix}-arrow {
			pointer-events: none;
			position: absolute;
			right: 12px;
			top: 8px;

			&-icon::after {
				content: '';
				border: 5px solid transparent;
				width: 0;
				height: 0;
				display: inline-block;
				border-top-color: @blue;
				transform: translateY(5px);
			}
		}
	}

	// =============== Focused ===============
	&-focused {
		.@{select-prefix}-selector {
			border-color: @blue !important;
		}
	}

	// ============== Dropdown ===============
	&-dropdown {
		border: 1px solid @grey;
		border-radius: @borderRadius;
		min-height: 100px;
		position: absolute;
		background: @white;
		overflow: hidden;
		z-index: 1000;

		&-hidden {
			display: none;
		}
	}

	// =============== Option ================
	&-item {
		padding: 6px @borderRadius;

		// >>> Group
		&-group {
			color: #999;
			font-weight: bold;
			font-size: 80%;
		}

		// >>> Option
		&-option {
			position: relative;

			&-grouped {
				padding-left: 24px;
			}

			.@{select-prefix}-item-option-state {
				position: absolute;
				right: 0;
				top: 4px;
				pointer-events: none;
			}

			// ------- Active -------
			&-active {
				background: @blue;
				color: @white;
			}

			// ------ Disabled ------
			&-disabled {
				color: #999;
			}

			&-state-icon {
				display: none;
			}
		}

		// >>> Empty
		&-empty {
			text-align: center;
			color: #999;
		}
	}
}

.@{select-prefix}-selection__choice-zoom {
	transition: all 0.3s;
}

.@{select-prefix}-selection__choice-zoom-appear {
	opacity: 0;
	transform: scale(0.5);

	&&-active {
		opacity: 1;
		transform: scale(1);
	}
}
.@{select-prefix}-selection__choice-zoom-leave {
	opacity: 1;
	transform: scale(1);

	&&-active {
		opacity: 0;
		transform: scale(0.5);
	}
}

.effect() {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
}

.@{select-prefix}-dropdown {
	&-slide-up-enter,
	&-slide-up-appear {
		.effect();
		opacity: 0;
		animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
		animation-play-state: paused;
	}

	&-slide-up-leave {
		.effect();
		opacity: 1;
		animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
		animation-play-state: paused;
	}

	&-slide-up-enter&-slide-up-enter-active&-placement-bottomLeft,
	&-slide-up-appear&-slide-up-appear-active&-placement-bottomLeft {
		animation-name: rcSelectDropdownSlideUpIn;
		animation-play-state: running;
	}

	&-slide-up-leave&-slide-up-leave-active&-placement-bottomLeft {
		animation-name: rcSelectDropdownSlideUpOut;
		animation-play-state: running;
	}

	&-slide-up-enter&-slide-up-enter-active&-placement-topLeft,
	&-slide-up-appear&-slide-up-appear-active&-placement-topLeft {
		animation-name: rcSelectDropdownSlideDownIn;
		animation-play-state: running;
	}

	&-slide-up-leave&-slide-up-leave-active&-placement-topLeft {
		animation-name: rcSelectDropdownSlideDownOut;
		animation-play-state: running;
	}
}

@keyframes rcSelectDropdownSlideUpIn {
	0% {
		opacity: 0;
		transform-origin: 0% 0%;
		transform: scaleY(0);
	}
	100% {
		opacity: 1;
		transform-origin: 0% 0%;
		transform: scaleY(1);
	}
}
@keyframes rcSelectDropdownSlideUpOut {
	0% {
		opacity: 1;
		transform-origin: 0% 0%;
		transform: scaleY(1);
	}
	100% {
		opacity: 0;
		transform-origin: 0% 0%;
		transform: scaleY(0);
	}
}

@keyframes rcSelectLoadingIcon {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.person {
	.@{select-prefix}-selector {
		.@{select-prefix}-selection-item {
			overflow: visible;
		}
	}
}