@import (reference) "../globals";
@import (reference) "../colors";
@import (reference) "../lib/functions";

.popupMenu {
	position: relative;
	display: inline-block;
	font-weight: normal;
	text-align: left;

	.popupTrigger {
		text-align: center;
		width: 27px;
		height: 32px;
		padding-top: 8px;
		.transition();
		color: #969AB8;
		cursor: pointer;

		&:hover {
			color: @blackAlmost;
		}
	}

	ul.popupContents {
		position: absolute;
		top: 32px;
		right: 0;
		z-index: 1;

		list-style: none;
		margin: 0;

		color: @blackAlmost;
		background-color: @white;
		border: 1px solid @grey;
		padding: 12px 18px;
		border-radius: @borderRadius;

		li {
			white-space: nowrap;
			word-break: keep-all;
			margin-bottom: 8px;

			&:hover {
				color: @greyDark;
				cursor: pointer;
			}


			&:last-of-type {
				margin-bottom: 0;
			}

			a { text-decoration: none; }
		}
	}
}
