@import (reference) "../../colors";
@import (reference) "calendar";

.calendarDaily {
	display: block;
	position: relative;
	width: 320px;

	/* GRID */
	&-row {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		border-bottom: 1px solid @calendar-border;

		&:last-child {
			border-bottom: none;
		}
	}

	&-cell, &-header-cell {
		flex-grow: 1;
		flex-basis: 0;
		font-weight: 700;
	}

	&-header-cell {
		text-align: center;
		padding: 6px 0;
		color: @blackAlmost;
	}

	&-cell {
		position: relative;
		padding: 5px;
		border-right: 1px solid @calendar-border;
		overflow: hidden;
		background-color: @calendar-neutral;
		transition: 0.25s ease-out;
		min-height: 45px;
		cursor: pointer;
		text-align: center;

		flex-grow: 0;
		flex-basis: calc(100% / 7);
		width: calc(100% / 7);

		&:hover {
			background-color: @calendar-bg;
			transition: 0.5s ease-out;
		}

		&:last-child {
			border-right: none;
		}

		&.weekend {
			background-color: #EAEDF4;
		}
	}

	&-number {
		display: inline-block;
		width: 32px;
		height: 32px;
		.font12();
		line-height: 1;
		text-align: center;
		padding-top: 9px;
		font-weight: 700;
		background-color: #F6F8FC;
		border-radius: 100%;

		&.selected {
			background-color: @calendar-main;
			color: @white;
		}
	}

	&-marker {
		position: absolute;
		height: 2px;
		bottom: 2px;
		right: 3px;
		left: 3px;
		background-color: @calendar-main;
		border-radius: 2px;
	}

	&-disabled {
		color: @calendar-text-light;
		pointer-events: none;
	}
}

.calendarDailyEntry {
	padding: 20px;
	border-radius: @borderRadius;
	color: @white;
	margin-bottom: 20px;

	&:last-of-type { margin-bottom: 0;}
}
