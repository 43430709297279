@import (reference) "../../colors";
@import (reference) "../../lib/typo";
@import (reference) "../../lib/helper";

.calendarMonthSelection {
	.clearFix();
	width: 200px;
	margin: 15px auto;

	&-left { float: left; }
	&-right { float: right; }
	&-center {
		text-align: center;
		margin: 0 40px;
		font-weight: bold;
		padding-top: 3px;
	}

	&-icon {
		cursor: pointer;
		transition: .15s ease-out;
		color: @white;
		background-color: @blue;
		border-radius: 100%;
		width: 24px;
		height: 24px;
		padding-top: 4px;
		text-align: center;

		&:hover {
			background-color: darken(@blue, 10);
		}
	}
}
