@import (reference) "../../colors";
@import (reference) "calendar";

.calendarMonthly {
	display: block;
	position: relative;

	/* GRID */
	&-row {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		border-bottom: 1px solid @calendar-border;
		position: relative;

		&:last-child {
			border-bottom: none;
		}
	}

	&-cell, &-header-cell {
		flex-grow: 1;
		flex-basis: 0;
		font-weight: 700;
	}

	&-header-cell {
		text-align: center;
		padding: 6px 0;
		color: @blackAlmost;
	}

	&-cell {
		position: relative;
		padding: 5px 5px 48px;
		border-right: 1px solid @calendar-border;
		overflow: hidden;
		background-color: @calendar-neutral;
		transition: 0.25s ease-out;
		min-height: 75px;
		cursor: pointer;

		flex-grow: 0;
		flex-basis: calc(100% / 7);
		width: calc(100% / 7);

		&:hover {
			background-color: @calendar-bg;
			transition: 0.5s ease-out;
		}

		&:last-of-type {
			border-right: none;
		}

		&.weekend {
			background-color: #EAEDF4;
		}
	}

	&-number {
		width: 32px;
		height: 32px;
		position: absolute;
		.font12();
		line-height: 1;
		text-align: center;
		bottom: 8px;
		left: 8px;
		padding-top: 9px;
		font-weight: 700;
		background-color: #F6F8FC;
		border-radius: 100%;

		&.selected {
			background-color: @calendar-main;
			color: @white;
		}
	}

	&-disabled {
		color: @calendar-text-light;
		pointer-events: none;
	}
}

.calendarMonthlyEntry {
	min-height: 19px;
	padding: 3px 8px;
	border-radius: @borderRadius;
	color: @white;
	.font10();
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
	text-align: center;

	&.size1 { width: (100%/7) * 1; }
	&.size2 { width: (100%/7) * 2; }
	&.size3 { width: (100%/7) * 3; }
	&.size4 { width: (100%/7) * 4; }
	&.size5 { width: (100%/7) * 5; }
	&.size6 { width: (100%/7) * 6; }
	&.size7 { width: (100%/7) * 7; }

	&.offset1 { left: (100%/7) * 1; }
	&.offset2 { left: (100%/7) * 2; }
	&.offset3 { left: (100%/7) * 3; }
	&.offset4 { left: (100%/7) * 4; }
	&.offset5 { left: (100%/7) * 5; }
	&.offset6 { left: (100%/7) * 6; }
}
