a.button, button {
	&.plus {
		min-width: auto;
		padding: 6px;

		.fas {
			text-align: center;
			display: block;
			width: 25px;
			height: 25px;
			padding-top: 5px;
			padding-left: 1px;
			background-color: darken(@blue, 10);
			border-radius: 100px;
			vertical-align: center;
		}

		&.grey {
			.fas {
				background-color: darken(#6A7188, 10);
			}
		}
	}
}