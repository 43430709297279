.floatLeft { float: left !important; }
.floatRight { float: right !important; }
.floatNone { float: none !important; }

.overflowHidden { overflow: hidden !important; }
.overflowVisible { overflow: visible !important; }

.positionStatic { position: static !important; }
.positionRelative { position: relative !important; }
.positionAbsolute { position: absolute !important; }
.positionFixed { position: fixed !important; }

.displayInlineBlock { display: inline-block; }
.displayBlock { display: block; }
.displayInline { display: inline; }
.displayNone, .hide, .hidden { display: none; }

.clear { clear: both; }
br.clear { font-size: 0; display: block; height: 0; }

.clearFix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.clearFixRemove {
	&:after {
		content: none;
		display: none;
		clear: none;
	}
}

.cursorPointer { cursor: pointer; }
.cursorDefault { cursor: default; }