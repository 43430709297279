@import (reference) "../globals";
@import (reference) "../colors";
@import (reference) "../lib/functions";

.boxTable {
	display: table;
	width: 100%;
	overflow: visible;

	.boxRow, .boxHeader {
		overflow: visible;
		display: table-row;
		.boxShadow();
		border-radius: @borderRadius;

		.boxCell {
			display: table-cell;
			padding: 12px 8px;
			background-color: @white;
			vertical-align: middle;

			&:first-of-type {
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
				padding-left: 24px;
			}
			&:last-of-type {
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
				padding-right: 24px;
			}
		}

		&.withBorder {
			.boxCell {
				border-top: 1px solid #E1E1E1;
				border-bottom: 1px solid #E1E1E1;

				&:first-child { border-left: 1px solid #E1E1E1; }
				&:last-child { border-right: 1px solid #E1E1E1; }
			}
		}

		&.selected {
			.boxCell {
				background-color: darken(@white, 4);
			}
		}

		&.spacer {
			.boxShadowOff();
			height: 12px;
		}
	}

	.boxHeader {
		.boxShadowOff();

		.boxCell {
			padding-top: 12px;
			padding-bottom: 12px;
			background-color: transparent;
			font-weight: normal;
			word-break: keep-all;
			white-space: nowrap;
		}
	}

	.boxSort {
		user-select: none;
		padding-left: 18px;
		position: relative;
		display: block;
		cursor: pointer;

		span {
			white-space: normal;
		}

		i {
			color: #969ABB;
			display: inline-block;
			padding: 1px 4px;
			position: absolute;
			top: 50%;
			margin-top: -8px;
			left: 0;
		}

		&:hover i { color: @blackAlmost; }
	}
}