@import (reference) "../colors";
@import (reference) "../lib/typo";


.teamMember {
	.boxStyle();
	padding: 24px 12px;
	text-align: center;
	position: relative;
	.font15();

	.popupMenu {
		position: absolute;
		right: 4px;
		top: 8px;
	}

	.avatar {
		margin-bottom: 24px;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	.department {
		background-color: #c2d3d2;
		border-radius: 20px;
		color: @white;
		display: inline-block;
		padding: 3px 10px;
		min-width: 64px;
		.font13();
	}

	.name {
		margin-bottom: 10px;
		font-weight: bold;
	}

	.contact {
		margin-top: 12px;
		line-height: 30px;
		overflow: hidden;

		a {
			margin-right: 4px;
			white-space: nowrap;

			span {
				display: none;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			&:last-of-type { margin-right: 0; }
		}
	}

	&.showFullContactDetails {
		.contact {
			text-align: left;

			a {
				margin-right: 0;
				text-overflow: ellipsis;
				display: block;
				overflow: hidden;

				span { display: inline; }
			}

			.contactDot { margin-bottom: 4px; }
		}
	}
}
