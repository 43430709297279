@reactConfirmAlertPrefix: react-confirm-alert;

body.@{reactConfirmAlertPrefix}-body-element {
	//overflow: hidden;
}

.@{reactConfirmAlertPrefix} {
	&-overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1000;
		background-color: rgba(210, 214, 225, 0.55);
		box-shadow: 0 2px 25px rgba(0, 0, 0, 0.7);

		display: flex;
		justify-content: center;
		align-items: center;
	}

	&-body {
		text-align: left;
		background-color: @white;
		border-radius: @borderRadius;
		border: 1px solid @white;
		min-width: 400px;
		padding: 24px;

		& > h3 {
			margin-top: 0;
		}
	}

	&-button-group {
		margin-top: 20px;
	}
}
