@import (reference) "../../colors";

@calendar-main: @blue;
@calendar-text-light: @greyDark;
@calendar-border: #D7DDE7;
@calendar-bg: darken(@white, 3);
@calendar-neutral: @white;

@calendarAbsence-bg: @white;

.calendarWrapper {
	border: 1px solid @calendar-border;
	border-radius: @borderRadius @borderRadius 0 0;
	background: @calendar-neutral;
}

@import "calendarDaily";
@import "calendarMonthly";
@import "calendarAbsence";
@import "monthSelection";
