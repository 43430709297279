.rc-calendar {
	box-sizing: border-box;
}
.rc-calendar * {
	box-sizing: border-box;
}
.rc-calendar-hidden {
	display: none;
}
.rc-calendar-input-wrap {
	position: relative;
	padding: 6px;
	border-bottom: 1px solid #e9e9e9;
}
.rc-calendar-input-wrap:after {
	content: '';
	clear: both;
}
.rc-calendar-date-input-wrap {
	overflow: hidden;
}
.rc-calendar-time-picker {
	position: absolute;
	width: 100%;
	top: 34px;
	background-color: white;
	height: 217px;
}
.rc-calendar-time-picker-panel {
	width: 100%;
	position: relative;
}
.rc-calendar-time-picker-panel .rc-time-picker-panel-input-wrap {
	display: none;
}
.rc-calendar-time-picker-panel .rc-time-picker-panel-inner {
	border: none;
	box-shadow: none;
}
.rc-calendar-time-picker-panel .rc-time-picker-panel-select {
	width: 84px;
	max-height: 217px;
}
.rc-calendar-time-picker-panel .rc-time-picker-panel-select li {
	text-align: center;
	padding: 0;
}
.rc-calendar-time-picker-wrap {
	float: left;
	width: 100%;
}
.rc-calendar-time-picker-wrap .rc-time-picker {
	width: 100%;
}
.rc-calendar-time-picker-wrap .rc-time-picker-input {
	padding: 0;
	border: 1px solid transparent;
	outline: 0;
	height: 22px;
}
.rc-calendar-time-picker-wrap .rc-time-picker-icon {
	display: none;
}
.rc-calendar-input {
	border: 1px solid transparent;
	width: 100%;
	color: #666;
	cursor: text;
	line-height: 1.5;
	outline: 0;
	height: 22px;
}
.rc-calendar-input-invalid {
	border-color: red;
}
.rc-calendar-clear-btn {
	z-index: 9999;
	position: absolute;
	right: 6px;
	cursor: pointer;
	overflow: hidden;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	top: 6px;
	margin: 0;
}
.rc-calendar-clear-btn:after {
	content: "x";
	font-size: 12px;
	color: #aaa;
	display: inline-block;
	line-height: 1;
	width: 20px;
	transition: color 0.3s ease;
}
.rc-calendar-clear-btn:hover:after {
	color: #666;
}
.rc-calendar-picker {
	position: absolute;
	left: -9999px;
	top: -9999px;
	z-index: 1000;
}
.rc-calendar-picker-hidden {
	display: none;
}
.rc-calendar-picker-slide-up-enter {
	animation-duration: .3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	display: block !important;
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-play-state: paused;
}
.rc-calendar-picker-slide-up-appear {
	animation-duration: .3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	display: block !important;
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-play-state: paused;
}
.rc-calendar-picker-slide-up-leave {
	animation-duration: .3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	display: block !important;
	opacity: 1;
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
	animation-play-state: paused;
}
.rc-calendar-picker-slide-up-enter.rc-calendar-picker-slide-up-enter-active.rc-calendar-picker-placement-bottomLeft,
.rc-calendar-picker-slide-up-enter.rc-calendar-picker-slide-up-enter-active.rc-calendar-picker-placement-bottomRight,
.rc-calendar-picker-slide-up-appear.rc-calendar-picker-slide-up-appear-active.rc-calendar-picker-placement-bottomLeft,
.rc-calendar-picker-slide-up-appear.rc-calendar-picker-slide-up-appear-active.rc-calendar-picker-placement-bottomRight {
	animation-name: rcDropdownSlideUpIn;
	animation-play-state: running;
}
.rc-calendar-picker-slide-up-enter.rc-calendar-picker-slide-up-enter-active.rc-calendar-picker-placement-topLeft,
.rc-calendar-picker-slide-up-enter.rc-calendar-picker-slide-up-enter-active.rc-calendar-picker-placement-topRight,
.rc-calendar-picker-slide-up-appear.rc-calendar-picker-slide-up-appear-active.rc-calendar-picker-placement-topLeft,
.rc-calendar-picker-slide-up-appear.rc-calendar-picker-slide-up-appear-active.rc-calendar-picker-placement-topRight {
	animation-name: rcDropdownSlideDownIn;
	animation-play-state: running;
}
.rc-calendar-picker-slide-up-leave.rc-calendar-picker-slide-up-leave-active.rc-calendar-picker-placement-bottomLeft,
.rc-calendar-picker-slide-up-leave.rc-calendar-picker-slide-up-leave-active.rc-calendar-picker-placement-bottomRight {
	animation-name: rcDropdownSlideUpOut;
	animation-play-state: running;
}
.rc-calendar-picker-slide-up-leave.rc-calendar-picker-slide-up-leave-active.rc-calendar-picker-placement-topLeft,
.rc-calendar-picker-slide-up-leave.rc-calendar-picker-slide-up-leave-active.rc-calendar-picker-placement-topRight {
	animation-name: rcDropdownSlideDownOut;
	animation-play-state: running;
}
@keyframes rcDropdownSlideUpIn {
	0% {
		opacity: 0;
		transform-origin: 0% 0%;
		transform: scaleY(0);
	}
	100% {
		opacity: 1;
		transform-origin: 0% 0%;
		transform: scaleY(1);
	}
}
@keyframes rcDropdownSlideUpOut {
	0% {
		opacity: 1;
		transform-origin: 0% 0%;
		transform: scaleY(1);
	}
	100% {
		opacity: 0;
		transform-origin: 0% 0%;
		transform: scaleY(0);
	}
}
@keyframes rcDropdownSlideDownIn {
	0% {
		opacity: 0;
		transform-origin: 100% 100%;
		transform: scaleY(0);
	}
	100% {
		opacity: 1;
		transform-origin: 100% 100%;
		transform: scaleY(1);
	}
}
@keyframes rcDropdownSlideDownOut {
	0% {
		opacity: 1;
		transform-origin: 100% 100%;
		transform: scaleY(1);
	}
	100% {
		opacity: 0;
		transform-origin: 100% 100%;
		transform: scaleY(0);
	}
}
.rc-calendar {
	position: relative;
	outline: none;
	font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei", "Microsoft Sans Serif", "WenQuanYi Micro Hei", sans-serif;
	width: 253px;
	list-style: none;
	font-size: 12px;
	text-align: left;
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 5px #ccc;
	background-clip: padding-box;
	border: 1px solid #ccc;
	line-height: 1.5;
}
.rc-calendar-date-panel,
.rc-calendar-panel {
	position: relative;
	outline: none;
}
.rc-calendar-week-number {
	width: 286px;
}
.rc-calendar-week-number-cell {
	text-align: center;
}
.rc-calendar-header {
	padding: 0 10px;
	height: 34px;
	line-height: 30px;
	text-align: center;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
	border-bottom: 1px solid #ccc;
}
.rc-calendar-header > a {
	font-weight: bold;
	display: inline-block;
	padding: 0px 5px;
	line-height: 34px;
	text-align: center;
	width: 30px;
}
.rc-calendar-header > a:hover {
	cursor: pointer;
	color: #23c0fa;
}
.rc-calendar-header .rc-calendar-prev-month-btn {
	position: absolute;
	left: 25px;
}
.rc-calendar-header .rc-calendar-prev-month-btn:after {
	content: '‹';
}
.rc-calendar-header .rc-calendar-next-month-btn {
	position: absolute;
	right: 25px;
}
.rc-calendar-header .rc-calendar-next-month-btn:after {
	content: '›';
}
.rc-calendar-year-select,
.rc-calendar-month-select,
.rc-calendar-day-select {
	display: inline-block;
	font-size: 12px;
	font-weight: bold;
	color: #666;
	padding: 0 8px;
	line-height: 34px;
}
.rc-calendar-year-select:hover,
.rc-calendar-month-select:hover,
.rc-calendar-day-select:hover {
	cursor: pointer;
	color: #23c0fa;
}
.rc-calendar-year-select.rc-calendar-time-status:hover,
.rc-calendar-month-select.rc-calendar-time-status:hover,
.rc-calendar-day-select.rc-calendar-time-status:hover {
	cursor: pointer;
	color: #666;
}
.rc-calendar-prev-month-btn,
.rc-calendar-next-month-btn,
.rc-calendar-prev-year-btn,
.rc-calendar-next-year-btn {
	position: absolute;
	top: 0;
	cursor: pointer;
	color: #999;
	font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei", "Microsoft Sans Serif", sans-serif;
	padding: 0 5px;
	font-size: 16px;
	display: inline-block;
	line-height: 34px;
}
.rc-calendar-prev-month-btn:hover,
.rc-calendar-next-month-btn:hover,
.rc-calendar-prev-year-btn:hover,
.rc-calendar-next-year-btn:hover {
	color: #23c0fa;
}
.rc-calendar-next-year-btn {
	right: 0;
}
.rc-calendar-next-year-btn:after {
	content: '»';
}
.rc-calendar-prev-year-btn {
	left: 0;
}
.rc-calendar-prev-year-btn:after {
	content: '«';
}
.rc-calendar-body {
	padding: 9px 10px 10px;
	height: 217px;
}
.rc-calendar table {
	border-collapse: collapse;
	max-width: 100%;
	background-color: transparent;
	width: 100%;
}
.rc-calendar table,
.rc-calendar td,
.rc-calendar th,
.rc-calendar td {
	border: none;
}
.rc-calendar-table {
	border-spacing: 0;
	margin-bottom: 0;
}
.rc-calendar-column-header {
	line-height: 18px;
	padding: 6px 0;
	width: 33px;
	text-align: center;
}
.rc-calendar-column-header .rc-calendar-column-header-inner {
	display: block;
	font-weight: normal;
}
.rc-calendar-week-number-header .rc-calendar-column-header-inner {
	display: none;
}
.rc-calendar-cell {
	padding: 1px 0;
}
.rc-calendar-date {
	display: block;
	margin: 0 auto;
	color: #666;
	border-radius: 4px 4px;
	width: 26px;
	height: 26px;
	padding: 0;
	background: transparent;
	line-height: 26px;
	text-align: center;
}
.rc-calendar-date:hover {
	background: #ebfaff;
	cursor: pointer;
}
.rc-calendar-selected-day .rc-calendar-date {
	background: #d9f4fe;
}
.rc-calendar-selected-date .rc-calendar-date {
	background: #3fc7fa;
	color: #fff;
}
.rc-calendar-selected-date .rc-calendar-date:hover {
	background: #3fc7fa;
}
.rc-calendar-today .rc-calendar-date {
	border: 1px solid #3fc7fa;
}
.rc-calendar-disabled-cell .rc-calendar-date {
	cursor: not-allowed;
	color: #bcbcbc;
	background: #f3f3f3;
	border-radius: 0;
	width: auto;
}
.rc-calendar-disabled-cell .rc-calendar-date:hover {
	background: #f3f3f3;
}
.rc-calendar-disabled-cell-first-of-row .rc-calendar-date {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.rc-calendar-disabled-cell-last-of-row .rc-calendar-date {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.rc-calendar-last-month-cell .rc-calendar-date,
.rc-calendar-next-month-btn-day .rc-calendar-date {
	color: #bbb;
}
.rc-calendar-footer {
	border-top: 1px solid #ccc;
	padding: 10px 0;
	text-align: center;
	position: relative;
}
.rc-calendar-footer .rc-time-picker {
	width: 90px;
}
.rc-calendar-footer .rc-time-picker-input {
	height: 24px;
}
.rc-calendar-footer-show-ok {
	text-align: right;
}
.rc-calendar-footer-show-ok .rc-calendar-footer-btn {
	padding-right: 12px;
}
.rc-calendar-footer-show-ok .rc-calendar-time-picker-btn {
	margin-left: 0;
	padding: 0 12px;
}
.rc-calendar-footer-show-ok .rc-calendar-today-btn {
	float: left;
	padding-left: 12px;
}
.rc-calendar-footer-btn {
	margin-top: 2px;
}
.rc-calendar-footer-btn:after {
	content: 'x';
	height: 0;
	font-size: 0;
	overflow: hidden;
	clear: both;
}
.rc-calendar-time-picker-btn {
	margin-left: 10px;
}
.rc-calendar-today-btn,
.rc-calendar-ok-btn,
.rc-calendar-time-picker-btn {
	display: inline-block;
	text-align: center;
	color: #f46830;
}
.rc-calendar-today-btn:hover,
.rc-calendar-ok-btn:hover,
.rc-calendar-time-picker-btn:hover {
	cursor: pointer;
	color: #23c0fa;
}
.rc-calendar-today-btn-disabled,
.rc-calendar-ok-btn-disabled,
.rc-calendar-time-picker-btn-disabled {
	color: #bbb;
}
.rc-calendar-today-btn-disabled:hover,
.rc-calendar-ok-btn-disabled:hover,
.rc-calendar-time-picker-btn-disabled:hover {
	color: #bbb;
}
.rc-calendar-today-btn {
	padding-left: 10px;
}
.rc-calendar-time-input {
	height: 25px;
	position: relative;
	display: inline-block;
	margin: 0 0;
	padding: 4px 10px;
	border-radius: 6px 6px;
	border: 1px solid #d9d9d9;
	background-color: #ffffff;
	color: #666;
	line-height: 1.5;
	transform: border 0.3s cubic-bezier(0.35, 0, 0.25, 1), background 0.3s cubic-bezier(0.35, 0, 0.25, 1), box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1);
	width: 40px;
}
.rc-calendar-time-input:hover {
	border-color: #23c0fa;
}
.rc-calendar-time-input:focus {
	border-color: #23c0fa;
	box-shadow: 0 0 3px #23c0fa;
}
.rc-calendar-time-panel {
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background: #ffffff;
	z-index: 10;
	position: absolute;
	outline: none;
}
.rc-calendar-time-panel-header {
	padding: 0 10px;
	height: 34px;
	line-height: 34px;
	position: relative;
	text-align: center;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
	border-bottom: 1px solid #ccc;
}
.rc-calendar-time-panel-body {
	padding: 9px 10px 10px;
}
.rc-calendar-time-panel-title {
	width: 180px;
	font-weight: bold;
	display: inline-block;
	padding: 4px 5px;
	text-align: center;
	height: 30px;
	line-height: 22px;
	border-radius: 4px;
}
.rc-calendar-time-panel-table {
	table-layout: fixed;
	width: 100%;
	height: 255px;
	border-collapse: separate;
}
.rc-calendar-time-panel-cell {
	text-align: center;
	height: 42px;
	vertical-align: middle;
}
.rc-calendar-time-panel-time {
	line-height: 26px;
	display: block;
	border-radius: 4px;
	width: 26px;
	margin: 0 auto;
}
.rc-calendar-time-panel-time:hover {
	background: #ebfaff;
	cursor: pointer;
}
.rc-calendar-time-panel-selected-cell .rc-calendar-time-panel-time {
	background: #3fc7fa;
	color: #fff;
}
.rc-calendar-time-panel-selected-cell .rc-calendar-time-panel-time:hover {
	background: #3fc7fa;
	color: #fff;
}
.rc-calendar-month-panel {
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background: #ffffff;
	z-index: 10;
	position: absolute;
	outline: none;
}
.rc-calendar-month-panel > div {
	height: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.rc-calendar-month-panel-hidden {
	display: none;
}
.rc-calendar-month-panel-header {
	padding: 0 10px;
	height: 34px;
	line-height: 30px;
	position: relative;
	text-align: center;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
	border-bottom: 1px solid #ccc;
}
.rc-calendar-month-panel-header > a {
	font-weight: bold;
	display: inline-block;
	padding: 4px 5px;
	text-align: center;
	width: 30px;
}
.rc-calendar-month-panel-header > a:hover {
	cursor: pointer;
	color: #23c0fa;
}
.rc-calendar-month-panel-prev-year-btn,
.rc-calendar-month-panel-next-year-btn {
	position: absolute;
	top: 0;
}
.rc-calendar-month-panel-next-year-btn:after {
	content: '»';
}
.rc-calendar-month-panel-prev-year-btn {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	left: 0;
}
.rc-calendar-month-panel-prev-year-btn:after {
	content: '«';
}
.rc-calendar-month-panel .rc-calendar-month-panel-year-select {
	width: 180px;
}
.rc-calendar-month-panel-year-select-arrow {
	display: none;
}
.rc-calendar-month-panel-next-year-btn {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	right: 0;
}
.rc-calendar-month-panel-body {
	-ms-flex: 1;
	flex: 1;
	padding: 9px 10px 10px;
}
.rc-calendar-month-panel-footer {
	border-top: 1px solid #ccc;
	line-height: 38px;
}
.rc-calendar-month-panel-table {
	table-layout: fixed;
	width: 100%;
	height: 100%;
	border-collapse: separate;
}
.rc-calendar-month-panel-cell {
	text-align: center;
}
.rc-calendar-month-panel-cell .rc-calendar-month-panel-month {
	display: block;
	width: 46px;
	margin: 0 auto;
	color: #666;
	border-radius: 4px 4px;
	height: 36px;
	padding: 0;
	background: transparent;
	line-height: 36px;
	text-align: center;
}
.rc-calendar-month-panel-cell .rc-calendar-month-panel-month:hover {
	background: #ebfaff;
	cursor: pointer;
}
.rc-calendar-month-panel-cell-disabled .rc-calendar-month-panel-month {
	color: #bfbfbf;
}
.rc-calendar-month-panel-cell-disabled .rc-calendar-month-panel-month:hover {
	background: white;
	cursor: not-allowed;
}
.rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month {
	background: #3fc7fa;
	color: #fff;
}
.rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month:hover {
	background: #3fc7fa;
	color: #fff;
}
.rc-calendar-month-header-wrap {
	position: relative;
	height: 308px;
}
.rc-calendar-year-panel {
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background: #ffffff;
	z-index: 10;
	position: absolute;
	outline: none;
}
.rc-calendar-year-panel > div {
	height: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.rc-calendar-year-panel-hidden {
	display: none;
}
.rc-calendar-year-panel-header {
	padding: 0 10px;
	height: 34px;
	line-height: 30px;
	position: relative;
	text-align: center;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
	border-bottom: 1px solid #ccc;
}
.rc-calendar-year-panel-header > a {
	font-weight: bold;
	display: inline-block;
	padding: 4px 5px;
	text-align: center;
	width: 30px;
}
.rc-calendar-year-panel-header > a:hover {
	cursor: pointer;
	color: #23c0fa;
}
.rc-calendar-year-panel-prev-decade-btn,
.rc-calendar-year-panel-next-decade-btn {
	position: absolute;
	top: 0;
}
.rc-calendar-year-panel-next-decade-btn:after {
	content: '»';
}
.rc-calendar-year-panel-prev-decade-btn {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	left: 0;
}
.rc-calendar-year-panel-prev-decade-btn:after {
	content: '«';
}
.rc-calendar-year-panel .rc-calendar-year-panel-decade-select {
	width: 180px;
}
.rc-calendar-year-panel-decade-select-arrow {
	display: none;
}
.rc-calendar-year-panel-next-decade-btn {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	right: 0;
}
.rc-calendar-year-panel-body {
	-ms-flex: 1;
	flex: 1;
	padding: 9px 10px 10px;
}
.rc-calendar-year-panel-footer {
	border-top: 1px solid #ccc;
	line-height: 38px;
}
.rc-calendar-year-panel-table {
	table-layout: fixed;
	width: 100%;
	height: 100%;
	border-collapse: separate;
}
.rc-calendar-year-panel-cell {
	text-align: center;
}
.rc-calendar-year-panel-year {
	display: block;
	width: 46px;
	margin: 0 auto;
	color: #666;
	border-radius: 4px 4px;
	height: 36px;
	padding: 0;
	background: transparent;
	line-height: 36px;
	text-align: center;
}
.rc-calendar-year-panel-year:hover {
	background: #ebfaff;
	cursor: pointer;
}
.rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year {
	background: #3fc7fa;
	color: #fff;
}
.rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year:hover {
	background: #3fc7fa;
	color: #fff;
}
.rc-calendar-year-panel-last-decade-cell .rc-calendar-year-panel-year,
.rc-calendar-year-panel-next-decade-cell .rc-calendar-year-panel-year {
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
	color: rgba(0, 0, 0, 0.25);
}
.rc-calendar-decade-panel {
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background: #ffffff;
	z-index: 10;
	position: absolute;
	outline: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.rc-calendar-decade-panel-hidden {
	display: none;
}
.rc-calendar-decade-panel-header {
	padding: 0 10px;
	height: 34px;
	line-height: 34px;
	position: relative;
	text-align: center;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
	border-bottom: 1px solid #ccc;
}
.rc-calendar-decade-panel-header > a {
	font-weight: bold;
	display: inline-block;
	padding: 1px 5px;
	text-align: center;
	width: 30px;
}
.rc-calendar-decade-panel-header > a:hover {
	cursor: pointer;
	color: #23c0fa;
}
.rc-calendar-decade-panel-prev-century-btn,
.rc-calendar-decade-panel-next-century-btn {
	position: absolute;
	top: 0;
}
.rc-calendar-decade-panel-next-century-btn:after {
	content: '»';
}
.rc-calendar-decade-panel-prev-century-btn {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	left: 0;
}
.rc-calendar-decade-panel-prev-century-btn:after {
	content: '«';
}
.rc-calendar-decade-panel-next-century-btn {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	right: 0;
}
.rc-calendar-decade-panel-body {
	-ms-flex: 1;
	flex: 1;
	padding: 9px 10px 10px;
}
.rc-calendar-decade-panel-footer {
	border-top: 1px solid #ccc;
	line-height: 38px;
}
.rc-calendar-decade-panel-table {
	table-layout: fixed;
	width: 100%;
	height: 100%;
	border-collapse: separate;
}
.rc-calendar-decade-panel-cell {
	text-align: center;
}
.rc-calendar-decade-panel-decade {
	display: block;
	margin: 0 auto;
	color: #666;
	border-radius: 4px 4px;
	height: 36px;
	padding: 0;
	background: transparent;
	line-height: 36px;
	text-align: center;
}
.rc-calendar-decade-panel-decade:hover {
	background: #ebfaff;
	cursor: pointer;
}
.rc-calendar-decade-panel-selected-cell .rc-calendar-decade-panel-decade {
	background: #3fc7fa;
	color: #fff;
}
.rc-calendar-decade-panel-selected-cell .rc-calendar-decade-panel-decade:hover {
	background: #3fc7fa;
	color: #fff;
}
.rc-calendar-decade-panel-last-century-cell .rc-calendar-decade-panel-decade,
.rc-calendar-decade-panel-next-century-cell .rc-calendar-decade-panel-decade {
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
	color: rgba(0, 0, 0, 0.25);
}
.rc-calendar-range {
	width: 502px;
	overflow: hidden;
}
.rc-calendar-range-part {
	width: 250px;
	position: relative;
}
.rc-calendar-range-part .rc-calendar-time-picker {
	top: 69px;
}
.rc-calendar-range-part .rc-calendar-time-picker-panel-select {
	width: 77px;
}
.rc-calendar-range-left {
	float: left;
}
.rc-calendar-range-left .rc-calendar-time-picker-panel-select:last-child {
	border-right: 1px solid #e9e9e9;
}
.rc-calendar-range-right {
	float: right;
}
.rc-calendar-range-right .rc-calendar-time-picker-panel {
	left: 21px;
}
.rc-calendar-range-right .rc-calendar-time-picker-panel-select:first-child {
	border-left: 1px solid #e9e9e9;
}
.rc-calendar-range-middle {
	position: absolute;
	margin-left: -10px;
	text-align: center;
	height: 35px;
	line-height: 35px;
}
.rc-calendar-range .rc-calendar-date-panel::after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}
.rc-calendar-range .rc-calendar-input-wrap {
	height: 35px;
}
.rc-calendar-range .rc-calendar-input,
.rc-calendar-range .rc-time-picker-input {
	padding: 1px 7px;
	height: 22px;
}
.rc-calendar-range .rc-calendar-body,
.rc-calendar-range .rc-calendar-decade-panel-body,
.rc-calendar-range .rc-calendar-year-panel-body,
.rc-calendar-range .rc-calendar-month-panel-body {
	border-bottom: 1px solid #e9e9e9;
}
.rc-calendar-range.rc-calendar-week-number {
	width: 574px;
}
.rc-calendar-range.rc-calendar-week-number .rc-calendar-range-part {
	width: 286px;
}
.rc-calendar-range.rc-calendar-week-number .rc-calendar-range-part .rc-calendar-time-picker {
	top: 69px;
}
.rc-calendar-range.rc-calendar-week-number .rc-calendar-range-part .rc-calendar-time-picker-panel-select {
	width: 89px;
}
.rc-calendar-range.rc-calendar-week-number .rc-calendar-range-right .rc-calendar-time-picker-panel {
	left: 36px;
}
.rc-calendar-range .rc-calendar-year-panel,
.rc-calendar-range .rc-calendar-month-panel,
.rc-calendar-range .rc-calendar-decade-panel {
	top: 35px;
}
.rc-calendar-range .rc-calendar-month-panel .rc-calendar-year-panel {
	top: 0;
}
.rc-calendar-range .rc-calendar-decade-panel-table,
.rc-calendar-range .rc-calendar-year-panel-table,
.rc-calendar-range .rc-calendar-month-panel-table {
	height: 198px;
}
.rc-calendar-range .rc-calendar-in-range-cell {
	background: #ebf4f8;
	border-radius: 0;
}
.rc-calendar-range-bottom {
	text-align: right;
}
.rc-calendar-range .rc-calendar-footer {
	border-top: none;
	padding: 0;
}
.rc-calendar-range .rc-calendar-footer-btn {
	padding: 10px 12px 10px 0;
}
.rc-calendar-range .rc-calendar-ok-btn {
	position: static;
}
.rc-calendar-range .rc-calendar-today-btn {
	float: left;
}
.rc-calendar-full {
	width: 275px;
}
.rc-calendar-full-header {
	padding: 5px 10px;
	text-align: center;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
	border-bottom: 1px solid #ccc;
	overflow: hidden;
}
.rc-calendar-full-header-month-select,
.rc-calendar-full-header-year-select {
	width: 70px;
	float: right;
	margin-right: 5px;
}
.rc-calendar-full-header-switcher {
	float: right;
	display: inline-block;
}
.rc-calendar-full-header-switcher-normal:hover {
	border-color: #23c0fa;
	box-shadow: 0 0 2px rgba(45, 183, 245, 0.8);
	cursor: pointer;
}
.rc-calendar-full-header-switcher-focus {
	border-color: #3fc7fa;
	background-color: #3fc7fa;
	color: #fff;
}
.rc-calendar-full-header-switcher > span {
	float: left;
	height: 28px;
	line-height: 24px;
	border: 1px solid #d9d9d9;
	padding: 0 10px;
	color: #666;
}
.rc-calendar-full-header-switcher > span:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	border-right: none;
}
.rc-calendar-full-header-switcher > span:last-child {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-left: none;
}
.rc-calendar-fullscreen {
	width: auto;
}
.rc-calendar-fullscreen .rc-calendar-full-header {
	border-bottom: none;
}
.rc-calendar-fullscreen .rc-calendar-column-header {
	text-align: right;
	padding-right: 12px;
}
.rc-calendar-fullscreen .rc-calendar-cell {
	padding: 0;
}
.rc-calendar-fullscreen .rc-calendar-cell .rc-calendar-date,
.rc-calendar-fullscreen .rc-calendar-month-panel-cell .rc-calendar-month-panel-month {
	display: block;
	height: 116px;
	width: auto;
	border-radius: 0;
	margin: 0 4px;
	border: none;
	border-top: 2px solid #eee;
	text-align: right;
	padding-right: 8px;
}
.rc-calendar-fullscreen .rc-calendar-selected-day .rc-calendar-date,
.rc-calendar-fullscreen .rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month {
	background-color: #ebfaff;
	color: #666;
}
.rc-calendar-fullscreen .rc-calendar-today .rc-calendar-date,
.rc-calendar-fullscreen .rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month {
	border-top-color: #3FC7FA;
	color: #3FC7FA;
}
