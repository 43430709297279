@import (reference) "globals";
@import (reference) "colors";
@import (reference) "lib/functions";

html {
	font-size: @baseFontSize + 0px;
	height: 100%;
}

body {
	background-color: @bg;
	color: @blackAlmost;
	font-family: @lato;
	height: 100%;
	line-height: 1.3;
}

a, .link {
	color: @blackAlmost;
	text-decoration: none;
	.transition(color, .4s, cubic-bezier(0, 0, 0.25, 1));
	cursor: pointer;

	&:hover { color: inherit; text-decoration: underline; }

	&.blue { color: @blue; }
}

hr {
	border-top-width: 0;
	border-bottom: 1px solid #D8D8D8;
	margin: 10px 0 4px;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0.5em 0;
	font-weight: normal;
	color: @headers;
}

h1, h2 {
	line-height: 1.2;
	font-weight: bold;
}

h3 + h2, h3 + h1 {
	margin-top: -6px !important;
}

h1 { font-size: 30/@baseFontSize + 0rem; }
h2 { font-size: 30/@baseFontSize + 0rem; }
h3 { font-size: 21/@baseFontSize + 0rem; }
h4 { font-size: 18/@baseFontSize + 0rem; }
h5 { font-size: 18/@baseFontSize + 0rem; }

ul { margin: 1.25em 0; }
p { margin: 0 0 1em; &:last-of-type { margin-bottom: 0;} }

strong, b { font-weight: 700; }

.fullWidth {
	width: 100%;
}

img.fullWidth {
	max-width: 100%;
	height: auto;
}

@media (--medium) {
	img {
		height: auto !important;
	}
}

@media only screen and (--small) {
	html { font-size: 14px; }
	h1, h2 { font-size: 30/14 + 0rem; }
	h3 { font-size: 21/14 + 0rem; }
	h4 { font-size: 18/14 + 0rem; }
}
