@import (reference) "../globals";
@import (reference) "../colors";

.pill {
	padding: 6px (@borderRadius * 1.6);
	border-radius: 24px;
	background-color: @blue;
	display: inline-block;
	text-align: center;

	&.green {
		background-color: #C0E88F;
	}

	&.blue {
		background-color: @blue;
		color: @white;
	}

	&.blueDark {
		background-color: #005CC8;
		color: @white;
	}

	&.eggplant {
		background-color: #C70577;
		color: @white;
	}

	&.dot {
		min-width: 32px;
		padding-left: 8px;
		padding-right: 8px;
		border-radius: 100%;
	}

	&.transparent {
		border: 1px solid @grey;
		background-color: transparent;
	}
}

&.tag {
	.pill();

	margin: 2px 8px 2px 0;
	font-weight: 100;
	.font12();
	border: 1px solid #f6e482;
	background-color: lighten(#f6e482, 10);

	&.selected {
		border-color: @greyDark;
		background-color: lighten(@greyDark, 10);
	}
}