@import (reference) "../colors";
@import (reference) "../lib/functions";
@import (reference) "../lib/typo";

.avatar {
	display: inline-block;
	width: 105px;
	height: 105px;
	border-radius: 100%;
	overflow: hidden;
	.boxShadow(0, 4px, 24px, rgba(191,197,214, 1.0));
	vertical-align: middle;
	border: 1px solid @grey;
	position: relative;

	&.medium {
		width: 32px;
		height: 32px;
		.boxShadow(0, 2px, 12px, rgba(191,197,214, 1.0));
	}

	&.small {
		width: 18px;
		height: 18px;
		.boxShadowOff;
	}

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&.editable {
		cursor: pointer;

		.editableMessage {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 4px 8px 10px;
			color: @white;
			text-align: center;
			background-color: rgba(0,0,0, 0.5);
			.transition();

			&:hover {
				padding-top: 6px;
				padding-bottom: 12px;
			}
		}
	}

	&.empty {
		.boxShadowOff();

		.plus {
			color: #FF9153;
			text-align: center;
			.font28();
			line-height: 100px;
			.transition();
		}

		&:hover {
			.plus {
				.font40();
			}
		}
	}

	&.editable.empty {
		border-color: #FF9153;
	}

	&.noShadow {
		.boxShadowOff();
	}
}

.rc-select-selection-item {
	.avatar { margin-top: -6px; }
}

.rc-select-multiple .rc-select-selection-item {
	.avatar { margin-top: -2px; }
}