@import (reference) "../globals";
@import (reference) "../colors";
@import (reference) "../lib/functions";
@import (reference) "../lib/helper";

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="password"],
input[type="number"],
select, textarea {
	&.pill {
		border-radius: 100px;
	}
}

input[type="search"] {
	&.pill {
		text-align: left;
	}
}

a.button, &button, input[type="submit"], input[type="button"] {
	&.pill {
		border: 1px solid transparent;
	}

	&.transparent {
		border: 1px solid @grey;

		&:hover {
			background-color: @grey;
		}
	}
}

