@import "../globals";
@import "../colors";

.rcModal {
	position: absolute;
	top: 50%;
	left: 50%;
	outline: none;
	transform: translate(-50%, -50%);
	background-color: @white;
	border-radius: @borderRadius;
	border: 1px solid @white;
	min-width: 720px;
	min-height: 420px;
	max-height: 90%;
	overflow: auto;

	.rcModelContents {
		padding: 24px;
		position: relative;
		min-height: 420px;
	}

	.rcModalActions {
		background-color: #F2F4F9;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		text-align: center;
		padding: 12px;
	}

	.rcModalClose {
		cursor: pointer;
		position: absolute;
		top: 12px;
		right: 12px;
		z-index: 1001;

		padding: 6px 12px;
		color: #273142;
		background-color: #F2F4F9;
		border-radius: 100px;

		i {
			color: #A6A6A6;
			margin-right: 4px;
			//color: @red;
		}

		&:hover {
			text-decoration: none;
			color: @blue;

			i { color: @red; }
		}
	}

	&.bigger {
		min-width: 980px;
	}

	&.withActions {
		.rcModelContents {
			padding-bottom: 38px + 24px;
		}
	}
}

.rcOverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background-color: rgba(210, 214, 225, 0.55);
	box-shadow: 0 2px 25px rgba(0, 0, 0, 0.7);
}

