@import (reference) "../globals";
@import (reference) "../colors";
@import (reference) "../lib/functions";
@import (reference) "../lib/helper";

form {
	padding: 0;
	margin-bottom: 0;
	.clearFix();
}
	a.button, button,
	input[type="text"],
	input[type="email"],
	input[type="search"],
	input[type="tel"],
	input[type="password"],
	input[type="number"],
	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	select, textarea {
		padding: 10px calc(@borderRadius * 1.6);
		background-color: @white;
		border: 1px solid @grey;
		min-width: @inputMinWidth;
		border-radius: @borderRadius;

		&.noMinimumWidth {
			min-width: auto;
		}
	}

	select { padding-right: calc(@borderRadius * 2.8)}
	
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="search"],
	input[type="password"],
	input[type="number"],
	select, textarea {
		box-sizing: border-box;
		line-height: inherit;

		&:focus { border-color: @blue; }
		&.error { border-color: @red; }

		&:read-only {
			background-color: darken(@white, 2);
			cursor: default;
			&:focus { border-color: @grey; }
		}
	}

	input.datePicker:read-only {
		background-color: @white;
	}
	
	textarea { min-width: @inputMinWidth * 2; }

	input[type="checkbox"],
	input[type="radio"] {
		margin-right: 4px;

		&:focus { outline: 1px solid @green; }
		&.error { outline: 1px solid @red; }
	}
	
	input[type="search"] {
		//background-image: resolve('icon/search.png');
		background-repeat: no-repeat;
		//padding-left: 28px;
	}

	a.button, button, input[type="submit"], input[type="button"] {
		background-color: @blue;
		color: @white;
		border: 0;
		cursor: pointer;
		.transition(all, .4s, cubic-bezier(0, 0, 0.25, 1));
		min-width: 90px;
		text-align: center;
		border-radius: 100px;
		padding-left: @borderRadius * 2.4;
		padding-right: @borderRadius * 2.4;
		line-height: 1.3;

		&:hover { background-color: darken(@blue, 20%); color: @white; }
		
		&:disabled {
			background-color: @greyDark !important;
			.fas { background-color: @grey !important; }

			&:hover { background-color: @greyDark !important; }
		}

		&.grey {
			background-color: #cbd1e2;
			color: @blackAlmost;

			&:hover { background-color: darken(#cbd1e2, 20%); }
		}

		&.darkBlue {
			background-color: darken(@blue, 30%);
			color: @white;

			&:hover { background-color: darken(@blue, 40%); }
		}

		&.green {
			background-color: @greenLight;
			color: @blackAlmost;

			&:hover { background-color: darken(@greenLight, 20%); }
		}

		&.red {
			background-color: @red;
			color: @white;

			&:hover { background-color: darken(@red, 20%); }
		}

		&.transparent {
			background-color: transparent;
			color: @blackAlmost;
		}
	}

	a.button, button {
		&.withChevron {
			position: relative;
			padding-right: 44px;
			min-height: 37px;

			.fas {
				text-align: center;
				position: absolute;
				right: 7px;
				top: 6px;
				display: block;
				width: 25px;
				height: 25px;
				padding-top: 5px;
				padding-left: 1px;
				background-color: darken(@blue, 10);
				border-radius: 100px;
				vertical-align: center;
			}
		}

		&.grey {
			background-color: #6A7188;
			color: @white;

			.fas {
				background-color: #545A6E;
			}

			&:hover { background-color: darken(#545A6E, 10%); }
		}
	}

	a.button {
		display: inline-block;
		color: @white;
		border: 0;
		text-decoration: none;
		.transition(all, .4s, cubic-bezier(0, 0, 0.25, 1));

		&:hover { text-decoration: none; }
	}

	a.button,
	input[type="text"],
	input[type="email"],
	input[type="search"],
	input[type="tel"],
	input[type="password"],
	input[type="number"],
	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button, select, textarea {
		&:disabled {
			opacity: 0.6;
		}
	}

	fieldset {
		border-color: @grey;
	}
	
	label {
		display: inline-block;
		vertical-align: top;
	}

	input[type="file"] {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	input[type="file"] + label {
		display: inline-block;
		background-color: @blue;
		color: @white;
		cursor: pointer;
		.transition(all, .4s, cubic-bezier(0, 0, 0.25, 1));
		border-radius: 100px;
		padding: 10px @borderRadius * 2.4;
		line-height: 1.3;

		&:hover { background-color: darken(@blue, 20%); color: @white; }
	}

