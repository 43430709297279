@import (reference) "../globals";
@import (reference) "../lib/helper";

@gapColumn: @gapVertical / 2;

.row {
	clear: both;
	text-align: center;
	.clearFix();
	
	.column {
		float: left;
		text-align: left;
		box-sizing: border-box;
		width: 100%;
		//min-height: 1px;
		//margin-bottom: @gapHorizontal;
		
		&.middle {
			float: none;
			margin-left: auto;
			margin-right: auto;
		}

		&.textLeft { text-align: left; }
		&.textRight { text-align: right; }
		&.textCenter { text-align: center; }
		&.textJustify { text-align: justify; }
		
		p:last-of-type { margin-bottom: 0; }
	}

	&.two .column, &.three .column, &.four .column, &.five .column {
		margin-left: @gapColumn;
		margin-right: @gapColumn;
	}

	&.two .column {
		width: calc((100% - 2 * @gapColumn) / 2);
		&:nth-child(2n+1) { clear: left; margin-left: 0; }
		&:nth-child(2n) { margin-right: 0; }
	}
	&.three .column {
		width: calc((100% - 4 * @gapColumn) / 3);
		&:nth-child(3n+1) { clear: left; margin-left: 0; }
		&:nth-child(3n) { margin-right: 0; }
	}
	&.four .column {
		width: calc((100% - 6 * @gapColumn) / 4);
		&:nth-child(4n+1) { clear: left; margin-left: 0; }
		&:nth-child(4n) { margin-right: 0; }

	}
	&.five .column {
		width: calc((100% - 8 * @gapColumn) / 5);
		&:nth-child(5n+1) { clear: left; margin-left: 0; }
		&:nth-child(5n) { margin-right: 0; }
	}

	&.centered { display: inline-block; }
}

@media only screen and (--normal) {
	.row {
		&.three .column {
			width: calc((100% - 2 * @gapColumn) / 2);
			&:nth-child(3n+1) { clear: none; margin-left: @gapColumn; }
			&:nth-child(3n) { margin-right: @gapColumn; }

			&:nth-child(2n+1) { clear: left; margin-left: 0; }
			&:nth-child(2n) { margin-right: 0; }
		}
		&.four .column {
			width: calc((100% - 4 * @gapColumn) / 3);
			&:nth-child(4n+1) { clear: none; margin-left: @gapColumn; }
			&:nth-child(4n) { margin-right: @gapColumn; }

			&:nth-child(3n+1) { clear: left; margin-left: 0; margin-right: @gapColumn; }
			&:nth-child(3n) { margin-right: 0; }
		}
		&.five .column {
			width: calc((100% - 4 * @gapColumn) / 3);
			&:nth-child(5n+1) { clear: none; margin-left: @gapColumn; }
			&:nth-child(5n) { margin-right: @gapColumn; }

			&:nth-child(3n+1) { clear: left; margin-left: 0; margin-right: @gapColumn; }
			&:nth-child(3n) { margin-right: 0; }
		}
	}
}

@media only screen and (--medium) {
	.row {
		&.two .column, &.three .column {
			width: 100%;
			clear: none !important;
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
		&.four .column, &.five .column {
			width: calc((100% - 2 * @gapColumn) / 2);
			&:nth-child(3n+1) { clear: none; margin-left: @gapColumn; }
			&:nth-child(3n) { margin-right: @gapColumn; }

			&:nth-child(2n+1) { clear: left; margin-left: 0; margin-right: @gapColumn; }
			&:nth-child(2n) { margin-right: 0; }
		}
	}
}

@media only screen and (--small) {
	.row {
		&.four .column, &.five .column {
			width: 100%;
			clear: none !important;
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}
}
