@import (reference) "../../colors";
@import (reference) "../../lib/typo";
@import (reference) "../../lib/helper";
@import (reference) "calendar";

@calendarAbsenceEntry-border-radius: 2px;
@calendarAbsenceEntry-padding: 3px;
@calendarAbsenceEntry-height: 40px;

.calendarAbsence {
	display: block;
	position: relative;
	width: 100%;
	border-radius: @borderRadius;
	overflow: hidden;
	background-color: @calendarAbsence-bg;
	border: 1px solid @calendar-border;

	&-row {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		border-bottom: 1px solid @calendar-border;

		&:last-child { border-bottom: none; }
	}

	&-cell, &-header-cell {
		flex-grow: 1;
	}

	&-cell {
		position: relative;
		border-right: 1px solid @calendar-border;
		min-height: @calendarAbsenceEntry-height + (2 * @calendarAbsenceEntry-padding);

		&:last-child {
			border-right: none;
		}
	}

	&-header-cell {
		text-align: center;
		text-transform: uppercase;
		.font12();
		padding: 8px 0;

		span {
			display: inline-block;
			min-width: 1.2em;
		}
	}

	&-user {
		width: 180px;
		padding: 7px 12px;
		line-height: 1.5;
		text-align: left;
	}

	&-selected {
		//background-color: darken(@calendarAbsence-bg, 7);
	}

	&-weekend {
		background-color: darken(@calendarAbsence-bg, 7);
	}
}


.calendarAbsenceEntry {
	top: @calendarAbsenceEntry-padding;
	bottom: @calendarAbsenceEntry-padding;
	position: relative;
	height: @calendarAbsenceEntry-height;

	&-start {
		border-top-left-radius: @calendarAbsenceEntry-border-radius;
		border-bottom-left-radius: @calendarAbsenceEntry-border-radius;
		width: calc(100% - @calendarAbsenceEntry-padding);
		left: @calendarAbsenceEntry-padding;
	}

	&-middle {
		right: -1px;
		left: -1px;
		width: calc(100% + 2px);
	}

	&-end {
		border-top-right-radius: @calendarAbsenceEntry-border-radius;
		border-bottom-right-radius: @calendarAbsenceEntry-border-radius;
		width: calc(100% - @calendarAbsenceEntry-padding);
		right: 1px;
	}

	&-start-end {
		left: 3px;
		right: 3px;
		position: absolute;
		border-radius: @calendarAbsenceEntry-border-radius;
	}
}
